import { oneOf, string } from "prop-types";

const Sun = ({
  variant,
  color,
  weight,
  opacity,
  // eslint-disable-next-line react/prop-types
  children,
  className,
  position,
}) => {
  Sun.propTypes = {
    position: oneOf([1, 2]),
    variant: oneOf([1, 2, 3, 4, 5]),
    color: oneOf([1, 2, 3]),
    weight: oneOf([1, 2, 3]),
    opacity: oneOf([1, 2, 3]),
    className: string,
  };

  const getPosition = () => {
    if (!position) {
      return "before";
    }
    switch (position) {
      case 1:
        return "before";
      case 2:
        return "after";

      default:
        return "before";
    }
  };

  const getVariant = () => {
    if (variant) {
      return variant;
    } else {
      return "1";
    }
  };

  const getBold = () => {
    if (!weight) {
      return "regular";
    }
    switch (weight) {
      case 1:
        return "light";
      case 2:
        return "regular";
      case 3:
        return "bold";
      default:
        "regular";
    }
  };

  const getOpacity = () => {
    if (!opacity) {
      return "3";
    } else {
      switch (opacity) {
        case 1:
          return "1";
        case 2:
          return "2";
        case 3:
          return "3";
        default:
          return "3";
      }
    }
  };

  const getColor = () => {
    if (!color) {
      return "alpha";
    } else {
      switch (color) {
        case 1:
          return "alpha";
        case 2:
          return "beta";
        case 3:
          return "gamma";
        default:
          return "alpha";
      }
    }
  };

  return (
    <span
      className={`pos-${getPosition()} sun-${getVariant()}-${getBold()} scribble-${getColor()}-${getOpacity()} ${
        className ? className : ""
      }`}
    >
      {children}
    </span>
  );
};

/**
 * To display a span with a sun.
 * @constructor
 * @param {number} type - the position of sun,
 * @param {number} variant - the variant of the sun,
 * @param {number} color - the color of the sun,
 * @param {number} weight - the font weight of the sun,
 * @param {number} opacity - the opacity of the sun,
 * @param {element} children - the text or element to display in the span,
 * @param {number} className - class to add to the span,
 */

export default Sun;
