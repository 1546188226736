import { Card, Col, Row } from "react-bootstrap";

import Arrow from "../../generics/gribouillis/arrow";
import Circle from "../../generics/gribouillis/circle";
import Draw from "../../generics/gribouillis/draw";
import Image from "next/image";
import face from "../../../assets/generics/graphical-svg/blue/face-1.svg";
import glasses from "../../../assets/generics/graphical-svg/yellow/glasses-2.svg";
import { object } from "prop-types";
import t from "../../../lib/cleanFrenchTypographie";

const Explain = ({ kabru }) => {
  Explain.propTypes = {
    kabru: object,
  };
  return (
    <>
      <Row className="mx-auto all py-1 pb-4 text-center">
        <Col xs={12} sm={10} md={8} lg={10} xl={8} className="my-1 mx-auto">
          <Arrow
            className="d-none d-lg-block"
            variant={5}
            weight={1}
            color={2}
            opacity={3}
            direction={2}
            size={1}
          />
          <h2 className="mt-4 text-primary pb-5 _g">
            <Draw variant={2} opacity={3}></Draw>On fait jouer la concurrence
            <Circle className="fw-bolder" variant={4}>
              &nbsp;pour vous.
            </Circle>
          </h2>
          <Row className="text-start">
            <Col xs={12} md={10} lg={6} className="m-auto">
              <Card className="bg-grey7">
                <Card.Title className="px-4 pb-0 pt-4 mb-0">
                  <h3 className="fw-bold h4">
                    {t(kabru.home.explain.cardLeft.title)}
                  </h3>
                </Card.Title>
                <Card.Body>
                  <p>
                    {t(kabru.home.explain.cardLeft.text_p1)}
                    <strong>{t(kabru.home.explain.cardLeft.text_p2)}</strong>
                    {t(kabru.home.explain.cardLeft.text_p3)}
                    <strong>{t(kabru.home.explain.cardLeft.text_p4)}</strong>
                    {t(kabru.home.explain.cardLeft.text_p5)}
                    <strong>{t(kabru.home.explain.cardLeft.text_p6)}</strong>
                  </p>
                  <br />
                  <div
                    style={{
                      width: "70%",
                      height: "200px",
                      position: "relative",
                    }}
                    className="d-flex m-auto"
                  >
                    <Image
                      className="m-auto"
                      src={glasses}
                      alt="On regarde tout à la loupe"
                      layout="fill"
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={10} lg={1} className="d-none d-lg-flex m-auto">
              <Arrow size={1} direction={1} weight={1} color={2} variant={3} />
            </Col>
            <Col xs={12} md={10} lg={1} className="d-flex d-lg-none m-auto">
              <Arrow size={1} direction={2} weight={1} color={2} variant={3} />
            </Col>
            <Col xs={12} md={10} lg={5} className="m-auto">
              <Card className="bg-yellow3">
                <Card.Title className="px-4 pb-0 pt-4 mb-0">
                  <h3 className="h4 fw-bold ">
                    {t(kabru.home.explain.cardRight.title)}
                  </h3>
                </Card.Title>
                <Card.Body className="mb-0 pb-0 text-center">
                  <p className="text-start">
                    {t(kabru.home.explain.cardRight.text1_p1)}
                    <strong>{t(kabru.home.explain.cardRight.text1_p2)}</strong>
                  </p>
                  <p className="text-start">
                    {t(kabru.home.explain.cardRight.text2_p1)}
                  </p>
                  <br />
                  <Image className="m-auto" src={face} alt="petit thé" />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Explain;
