import { Col, Container, Row } from "react-bootstrap";

import CTA from "../generics/cta";

const BannerFooter = () => {
  return (
    <Container fluid className="bg-blue2">
      <Row className="d-flex all mx-auto py-5">
        <Col xs={0} sm={1} md={1} lg={1} xl={2}></Col>
        <Col xs={12} sm={10} md={10} lg={7} xl={6} className="my-auto">
          <h2 className="text-white">
            Vérifions vos factures une fois pour toutes.
            <br />
            <small className="text-white">
              C’est l’affaire de quelques clics.
            </small>
          </h2>
        </Col>
        <Col sm={1} md={1} className="d-lg-none d-none d-sm-block"></Col>
        <Col sm={1} md={1} className="d-lg-none d-none d-sm-block"></Col>
        <Col
          xs={12}
          sm={10}
          md={10}
          lg={3}
          xl={2}
          className="my-2 d-flex justify-content-end"
        >
          <CTA lg>Faire le test</CTA>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1} xl={2}></Col>
      </Row>
    </Container>
  );
};

export default BannerFooter;
