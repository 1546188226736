import { useEffect, useState } from "react";

import Cookies from "js-cookie";
import qs from "qs";
import { useDispatch } from "react-redux";

const listeUtm = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
  "member_id",
  "gclid",
  "fbclid",
  "msclkid",
];

const UrlTracker = () => {
  const [paramsInfos, setParamsInfos] = useState({});
  const [isDone, done] = useState(false);
  const dispatch = useDispatch();
  const [isMounted, setIsMounted] = useState(false);
  const [t, st] = useState(0);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    typeof window.openCookiesManager !== "undefined" &&
      dispatch({ type: "setLoaded" });
    typeof window.openCookiesManager === "undefined" &&
      setTimeout(() => {
        st(t + 1);
      }, 1000);
  }, [t]);

  const takeInfos = () => {
    return new Promise((resolve) => {
      const pushState = (param) => {
        if (listeUtm.includes(param[0])) {
          setParamsInfos(
            Object.assign(paramsInfos, {
              [param[0]]: param[1],
            })
          );
        }
        if (param[0] === "id") {
          let cookieValue = "";
          try {
            const pk = Cookies.get("annapurnaIds").split("$")[1];
            cookieValue = param[1].concat("$", pk);
          } catch (error) {
            cookieValue = param[1];
          }
          Cookies.set("annapurnaIds", cookieValue, { expires: 30 });
          setParamsInfos(
            Object.assign(paramsInfos, {
              client_id: param[1],
            })
          );
        }
        if (param[0] === "code") {
          const cookieValue = param[1];
          if (cookieValue !== null) {
            Cookies.set("himalaya_referral", cookieValue, { expires: 30 });
          }
        }
      };
      let parameters = Object.entries(
        qs.parse(window.location.search.substring(1))
      );
      parameters.forEach((param) => pushState(param));
      resolve();
    });
  };

  const pushToGtm = () => {
    done(true);
    return new Promise((resolve) => {
      let infosToPush = Object.assign({ event: "originInfos" }, paramsInfos);
      dispatch({
        type: "setDataLayer",
        content: infosToPush,
      });
      resolve();
    });
  };
  isMounted && !isDone && takeInfos().then(pushToGtm());
  return null;
};

export default UrlTracker;
