import { oneOf, string } from "prop-types";

const Arrow = ({
  variant,
  color,
  weight,
  opacity,
  direction,
  size,
  className,
}) => {
  Arrow.propTypes = {
    direction: oneOf([1, 2, 3, 4]),
    variant: oneOf([1, 2, 3, 4, 5]),
    color: oneOf([1, 2, 3]),
    size: oneOf([1, 2, 3]),
    weight: oneOf([1, 2, 3]),
    opacity: oneOf([1, 2, 3]),
    className: string,
  };

  const getDirection = () => {
    if (!direction) {
      return "right";
    }
    switch (direction) {
      case 1:
        return "right";
      case 2:
        return "top";
      case 3:
        return "left";
      case 4:
        return "bottom";

      default:
        return "right";
    }
  };

  const getSize = () => {
    if (!size) {
      return "md";
    }
    switch (size) {
      case 1:
        return "sm";
      case 2:
        return "md";
      case 3:
        return "lg";

      default:
        return "md";
    }
  };

  const getVariant = () => {
    if (variant) {
      return variant;
    } else {
      return "1";
    }
  };

  const getBold = () => {
    if (!weight) {
      return "regular";
    }
    switch (weight) {
      case 1:
        return "light";
      case 2:
        return "regular";
      case 3:
        return "bold";
      default:
        "regular";
    }
  };

  const getOpacity = () => {
    if (!opacity) {
      return "3";
    } else {
      switch (opacity) {
        case 1:
          return "1";
        case 2:
          return "2";
        case 3:
          return "3";
        default:
          return "3";
      }
    }
  };

  const getColor = () => {
    if (!color) {
      return "alpha";
    } else {
      switch (color) {
        case 1:
          return "alpha";
        case 2:
          return "beta";
        case 3:
          return "gamma";
        default:
          return "alpha";
      }
    }
  };

  return (
    <div className="_gb d-flex m-auto">
      <div
        className={`m-auto arrow-size-${getSize()} dir-${getDirection()} arrow-${getVariant()}-${getBold()} scribble-${getColor()}-${getOpacity()} ${
          className ? className : ""
        } `}
      ></div>
    </div>
  );
};

/**
 * To display a span with a arrow.
 * @constructor
 * @param {number} direction - the direction of arrow,
 * @param {number} variant - the variant of the arrow,
 * @param {number} color - the color of the arrow,
 * @param {number} weight - the font weight of the arrow,
 * @param {number} opacity - the opacity of the arrow,
 */

export default Arrow;
