import { string } from "prop-types";

const cleanFrenchTypography = (str) => {
  cleanFrenchTypography.propTypes = {
    str: string,
  };
  try {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: str
            .replaceAll("/ /", "\u00a0")
            .replaceAll("'", "’")
            .replaceAll(" ?", "\u00a0?")
            .replaceAll(" !", "\u00a0!")
            .replaceAll(" »", "\u00a0»")
            .replaceAll("«", "«\u00a0")
            .replaceAll(" :", "\u00a0:")
            .replaceAll(" €", "\u00a0€")
            .replaceAll(" euros", "\u00a0euros"),
        }}
      ></span>
    );
  } catch (error) {
    return <span></span>;
  }
};

export default cleanFrenchTypography;
