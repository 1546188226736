import { oneOf, string } from "prop-types";

const Circle = ({
  variant,
  margin,
  color,
  weight,
  opacity,
  // eslint-disable-next-line react/prop-types
  children,
  className,
}) => {
  Circle.propTypes = {
    variant: oneOf([1, 2, 3, 4, 5]),
    margin: oneOf([1, 2, 3]),
    color: oneOf([1, 2, 3]),
    weight: oneOf([1, 2, 3]),
    opacity: oneOf([1, 2, 3]),
    className: string,
  };

  const getVariant = () => {
    if (variant) {
      return variant;
    } else {
      return "1";
    }
  };

  const getBold = () => {
    if (!weight) {
      return "regular";
    }
    switch (weight) {
      case 1:
        return "light";
      case 2:
        return "regular";
      case 3:
        return "bold";
      default:
        "regular";
    }
  };

  const getOpacity = () => {
    if (!opacity) {
      return "3";
    } else {
      switch (opacity) {
        case 1:
          return "1";
        case 2:
          return "2";
        case 3:
          return "3";
        default:
          return "4";
      }
    }
  };

  const getMargin = () => {
    switch (margin) {
      case 1:
        return "m-circle-s";
      case 2:
        return "m-circle-m";
      case 3:
        return "m-circle-l";

      default:
        return "m-circle-m";
    }
  };

  const getColor = () => {
    if (!color) {
      return "alpha";
    } else {
      switch (color) {
        case 1:
          return "alpha";
        case 2:
          return "beta";
        case 3:
          return "gamma";
        default:
          return "alpha";
      }
    }
  };

  return (
    <span
      className={`circle-${getVariant()}-${getBold()} scribble-${getColor()}-${getOpacity()} ${getMargin()} ${
        className ? className : ""
      }`}
    >
      {children}
    </span>
  );
};

/**
 * To display a span with a gribouilli.
 * @constructor
 * @param {number} variant - the variant of the gribouilli,
 * @param {number} margin - the margin around the gribouilli,
 * @param {number} color - the color of the gribouilli,
 * @param {number} weight - the font weight of the gribouilli,
 * @param {number} opacity - the opacity of the gribouilli,
 * @param {element} children - the text or element to display in the span,
 * @param {number} className - class to add to the span,
 */

export default Circle;
