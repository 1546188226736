import { Col, Row } from "react-bootstrap";
import { element, object, string } from "prop-types";

import Image from "next/image";

const HeroHome = (props) => {
  HeroHome.propTypes = {
    order: string,
    height: string,
    title: object,
    children: element,
    image: object,
  };
  return (
    <Row className={`my-5 mx-auto all text-primary ${props.order} `}>
      <Col xs={12} sm={1} md={2} lg={1} xl={2}></Col>
      <Col
        xs={12}
        sm={10}
        md={8}
        lg={6}
        xl={5}
        className={props.height === "small" ? "my-auto py-2" : "my-auto py-4"}
      >
        <h2 className="fw-bold h3 mb-3 _g pt-3">{props.title}</h2>
        <div className="lead">{props.children}</div>
      </Col>
      <Col sm={1} md={2} className="d-lg-none d-none d-sm-block"></Col>
      <Col sm={1} md={2} className="d-lg-none d-none d-sm-block"></Col>
      <Col
        xs={12}
        sm={10}
        md={8}
        lg={4}
        xl={3}
        className="d-flex justify-content-center"
      >
        <div
          style={{
            width: "70%",
            height: "70%",
            minHeight: "300px",
            position: "relative",
          }}
          className="d-flex m-auto"
        >
          <Image
            className="m-auto"
            src={props.image}
            alt="simple à utiliser"
            layout="fill"
          />
        </div>
      </Col>

      <Col xs={12} sm={1} md={2} lg={1} xl={2}></Col>
    </Row>
  );
};

export default HeroHome;
