import { Card, Col, Container, Row } from "react-bootstrap";
import { element, object, string } from "prop-types";
import {
  faLanguage,
  faLockAlt,
  faSearch,
  faShieldCheck,
} from "@fortawesome/pro-light-svg-icons";

import Circle from "../../generics/gribouillis/circle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "next/image";
import Link from "next/link";
import gift from "../../../assets/generics/graphical-svg/orange/face-2.svg";
import styles from "../../../styles/module/kabru/RassureCards.module.scss";
import t from "../../../lib/cleanFrenchTypographie";

const RassureCards = ({ kabru }) => {
  RassureCards.propTypes = {
    kabru: object,
  };

  const Ca = ({ icon, title, text }) => {
    Ca.propTypes = {
      title: string,
      text: element,
      icon: element,
    };
    return (
      <>
        <Card className={styles.cardRassure}>
          <Card.Title className="p-3 pb-1 mb-0 d-flex">
            <div className={styles.iconService}>
              <FontAwesomeIcon
                className="m-auto text-cta"
                size="lg"
                icon={icon}
              />
            </div>{" "}
            <p className="pt-2 ps-3 fw-bold text-primary _g">{title}</p>
          </Card.Title>
          <Card.Body className="pt-1 text-primary">{text}</Card.Body>
        </Card>
      </>
    );
  };

  return (
    <>
      <Container fluid className="my-5">
        <Row className="mx-auto all">
          <Col xs={12} sm={1} md={2} lg={1} xl={2} className="mx-0"></Col>
          <Col xs={12} sm={10} md={8} lg={4} xl={4} className="p-3 d-flex">
            <div
              style={{ width: "70%", height: "70%", position: "relative" }}
              className="d-flex m-auto"
            >
              <Image
                className="m-auto"
                src={gift}
                alt="tout le monde est gagnant"
                layout="fill"
              />
            </div>
          </Col>
          <Col sm={1} md={2} className="d-lg-none d-none d-sm-block"></Col>
          <Col sm={1} md={2} className="d-lg-none d-none d-sm-block"></Col>
          <Col xs={12} sm={10} md={8} lg={6} xl={4} className="m-0">
            <div className={styles.cardContent}>
              <Ca
                icon={faLanguage}
                text={<p>{t(kabru.home.rassureCards.card1.text)}</p>}
                title={t(kabru.home.rassureCards.card1.title)}
              />
              <Ca
                icon={faSearch}
                text={
                  <p>
                    {t(kabru.home.rassureCards.card2.text_p1)}
                    <Link prefetch={false} href="/comment-ca-marche">
                      <strong
                        role="button"
                        className="text-decoration-underline"
                      >
                        {t(kabru.home.rassureCards.card2.text_p2)}
                      </strong>
                    </Link>
                  </p>
                }
                title={
                  <Circle>{t(kabru.home.rassureCards.card2.title)}</Circle>
                }
              />
              <Ca
                icon={faShieldCheck}
                text={<p>{t(kabru.home.rassureCards.card3.text)}</p>}
                title={t(kabru.home.rassureCards.card3.title)}
              />
              <Ca
                icon={faLockAlt}
                text={<p>{t(kabru.home.rassureCards.card4.text)}</p>}
                title={t(kabru.home.rassureCards.card4.title)}
              />
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1} xl={1}></Col>
        </Row>
      </Container>
    </>
  );
};

export default RassureCards;
