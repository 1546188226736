import Circle from "../../generics/gribouillis/circle";
import HeroHome from "./heroHome";
import Line from "../../generics/gribouillis/line";
import bill from "../../../assets/generics/graphical-svg/yellow/bill-3.svg";
import plane from "../../../assets/generics/graphical-svg/yellow/plane-1.svg";
import stats from "../../../assets/generics/graphical-svg/yellow/stats-1.svg";

const HerosHome = () => {
  return (
    <>
      <HeroHome
        title={
          <>
            Pourquoi vous payez une{" "}
            <Line color={3} weight={2} margin={2} variant={3}>
              taxe&nbsp;de&nbsp;fidélité
            </Line>
          </>
        }
        order="flex-row-reverse"
        image={stats}
      >
        <>
          <p className="_g">
            <strong>
              Vous connaissez sans doute l’impression de payer trop cher
            </strong>{" "}
            pour un abonnement. Et puis, à l’idée de se pencher sur une{" "}
            <Line variant={2} color={3} className="fw-bolder">
              facture&nbsp;incompréhensible
            </Line>{" "}
            et une{" "}
            <Line variant={2} color={3} className="fw-bolder">
              armée&nbsp;d’offres
            </Line>
            , vous baissez les bras.
          </p>
          <p className="_g">
            C’est ce qui fait qu’<strong>on reste coincé</strong> chez un
            fournisseur et qu’on continue de payer cher, alors que le bon sens
            voudrait qu’on économise avec le temps.
          </p>
          <p className="_g">
            Cette différence, ça s’appelle la{" "}
            <Circle variant={3} color={3} className="fw-bold">
              &nbsp;taxe&nbsp;de&nbsp;fidélité&nbsp;
            </Circle>
            .
          </p>
        </>
      </HeroHome>
      <HeroHome
        title={
          <>
            <Circle variant={1} color={3}>
              Comprenez-vous
            </Circle>{" "}
            vos factures&nbsp;?
          </>
        }
        order="flex-row"
        image={bill}
      >
        <>
          <p>
            Vous vous êtes déjà demandé s’il y avait quelqu’un dont le métier
            était de rendre les factures compliquées ?
          </p>
          <p>
            Probablement la personne qui a inventé le coup de la ligne de
            facture annulée trois lignes plus bas, juste pour écrire les mots «
            remise exceptionnelle » et vous donner{" "}
            <span className="fw-bold">
              l’impression d’avoir fait l’affaire du siècle.
            </span>{" "}
          </p>
          <p className="_g">
            Cherpas vous traduit cette langue de bois en{" "}
            <Line variant={5} color={3}>
              langue&nbsp;française.
            </Line>
          </p>
        </>
      </HeroHome>
      <HeroHome
        title={
          <>
            <Circle margin={3} variant={4} color={3}>
              Finie&nbsp;la&nbsp;paperasse
            </Circle>
            &nbsp;!
          </>
        }
        order="flex-row-reverse"
        image={plane}
      >
        <>
          <p>
            Un sherpa, c’est un guide et un porteur en haute montagne. Cherpas,
            c’est votre guide et porteur dans la redoutable Montagne de
            Paperasse.
          </p>
          <p>
            Même si vous n’êtes pas victime de phobie administrative, rares sont
            les gens qui prennent plaisir à éplucher leurs factures, remplir des
            formulaires et affranchir le tout au tarif en vigueur.
          </p>
          <p className="_g">
            Du coup,{" "}
            <Line variant={4} color={3} className="fw-bolder">
              on s’occupe des démarches à votre place.
            </Line>
          </p>
        </>
      </HeroHome>
    </>
  );
};

export default HerosHome;
